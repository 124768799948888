<template>
  <div class="pageBox">
    <div class="page-list personnelfiles flex-1">
      <!-- 运费设置 -->
      <h2 class="twoh2">
        <span class="tpointer"></span>
        <span class="ttxt">运费设置</span>
      </h2>
      <div class="content1">
        <a-row class="mgb-12">
          <span class="contleft">包邮门槛
            <a-tooltip>
              <template slot="title">
                快递订单中排出包邮产品外,需满多少金额包邮
              </template>
              <a-icon
                type="info-circle"
                style="font-size:16px;"
                :rotate="180"
                class="font5"
              />
            </a-tooltip> </span>
          <span class="contlrgt">未达到包邮门槛时,快递订单收取运费 </span>
        </a-row>
        <a-row class="mgb-40">
          <span class="contleft">
            <a-input
              class="ant-input_1 wid-180"
              v-model="query.money"
              suffix="元"
              placeholder="输入金额"
            />
          </span>
          <span class="contlrgt">
            <a-input
              class="ant-input_1 wid-180"
              v-model="query.pomoney"
              suffix="元"
              placeholder="输入金额"
            />
          </span>
        </a-row>
      </div>
      <!-- 收货设置 -->
      <h2 class="twoh2">
        <span class="tpointer"></span>
        <span class="ttxt">收货设置</span>
      </h2>
      <div class="content1 mgb-40">
        <a-row class="mgb-12">
          <span class=" ">快递发货后,订单自动确认收货时间
            <a-tooltip>
              <template slot="title">
                快递发货后,多少天自动将待收货订单状态变更为已完成
              </template>
              <a-icon
                type="info-circle"
                style="font-size:16px;"
                :rotate="180"
              />
            </a-tooltip>
          </span>
        </a-row>
        <a-row>
          <a-radio-group
            name="radioGroup"
            class="nomal_radio radio_nomal1"
            v-model="query.setdays"
          >
            <a-radio :value="1">
              不设置
            </a-radio>
            <a-radio :value="2">
              设置
            </a-radio>
          </a-radio-group>
          <a-input
            class="ant-input_1 wid-180"
            v-model="query.days"
            suffix="天"
            placeholder="输入天数"
          />
        </a-row>

      </div>
      <!-- 取消订单设置 -->
      <h2 class="twoh2">
        <span class="tpointer"></span>
        <span class="ttxt">取消订单设置</span>
      </h2>
      <div class="content1 mgb-40">
        <a-row class="mgb-12">
          <span class=" ">商品未支付取消订单时间
            <a-tooltip>
              <template slot="title">
                待支付订单,到达设置取消时间后,订单状态自动从待支付变更为已取消;
              </template>
              <a-icon
                type="info-circle"
                style="font-size:16px;"
                :rotate="180"
              />
            </a-tooltip>
          </span>
        </a-row>
        <a-row>
          <div class="cominput flex wid-180 ant-input_1 align-item-center">
            <a-input
              v-model="query.times"
              class="nob_input  "
            />
            <div class="line"> <span></span> </div>
            <a-select
              v-model="query.times_type"
              class="nob_select"
              default-value="1"
              @change="handleChange"
            >
              <a-select-option value="1">
                分钟
              </a-select-option>
              <a-select-option value="2">
                小时
              </a-select-option>
              <a-select-option value="3">
                天
              </a-select-option>
            </a-select>

          </div>
        </a-row>

      </div>

      <div class="btns">
        <a-button
          type="primary"
          class="stepbtnnext1 bigheightbn wid-80"
        >保存</a-button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      query: {
        money: undefined,
        pomoney: undefined,
        setdays: 1,
        days: undefined,
        times: undefined,
        times_type: '1',
      },
    }
  },
  created() {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.personnelfiles {
  margin: 18px 12px 18px 18px;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  height: calc(100% - 87px);
  padding: 30px 0 0 35px;
  // position: relative;
  .twoh2 {
    display: flex;
    margin-bottom: 20px;
    .tpointer {
      display: inline-block;
      width: 3px;
      height: 10px;
      background: @primaryColor;
      border-radius: 1px;
      margin: 7px 8px 0 0;
    }
    .ttxt {
      height: 22px;
      font-size: 18px;
      font-weight: 600;
      color: @fontColor1;
      line-height: 25px;
    }
  }
  .btns {
    position: fixed;
    bottom: 40px;
    left: 231px;
  }
}
.wid-180 {
  width: 180px;
}
.mgb-40 {
  margin-bottom: 40px;
}
.contleft {
  display: inline-block;
  width: 300px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
}
.font5 {
  color: @fontColor5;
}
// 搜索下拉组合
.cominput {
  width: 180px;
  height: 34px;
  border-radius: 4px;
  .nob_input {
    border: none !important;
    box-shadow: none !important;
    width: 105px;
  }
  .line {
    height: 32px;
    width: 1px;
    background: @inputbgDColor;
    text-align: center;
    line-height: 34px;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 1px;
      height: 20px;
      background: #b0c3c2;
    }
  }
  .nob_select {
    border: none !important;
    box-shadow: none !important;
    background: @inputbgDColor;
    height: 32px;
    /deep/.ant-select-selection {
      border: none !important;
      box-shadow: none !important;
      background: @inputbgDColor;
    }
  }
}
.ant-input_1.cominput {
  border: 1px solid @inputborderDColor;
  box-shadow: inset 0px 3px 0px 0px @inputinsertDColor;
  background: @inputbgDColor;

  &::placeholder {
    font-size: @inputfontDColor;
    font-weight: 100;
  }

  &:hover {
    border: 1px solid @inputborderHColor;
    box-shadow: inset 0px 3px 0px 0px @inputinsertHColor;
    background: @inputbgHColor;

    &::placeholder {
      font-size: @inputfontHColor;
      font-weight: 100;
      color: @fontColor6;
    }
  }

  &:focus,
  &:active {
    border: 1px solid @inputborderFColor;
    box-shadow: inset 0px 3px 0px 0px @inputinsertFColor;
    background: @inputbgFColor;

    &::placeholder {
      font-size: @inputfontFColor;
      font-weight: 100;
      color: @fontColor6;
    }
  }
}
</style>